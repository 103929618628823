<template>
    <section class="first-section">
        <component
            v-bind:is="componentName"
            :pageData="pageData"
            v-if="componentName"
        />

        <div class="container" v-else>
            <div class="row">
                <div class="col-xl-6 offset-xl-3 text-center">
                    <h1 class="mt-100">
                        <h1 class="">{{ pageData.name }}</h1>
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-html="pageData.content"></div>
            </div>
        </div>
    </section>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Static",
    data() {
        return {
            pageData: {},
            isNotFound: false
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.getPage();
            },
            deep: true
        }
    },
    created() {
        this.getPage();
    },
    computed: {
        componentName: function() {
            if (this.pageData && this.pageData.component_name) {
                return this.pageData.component_name.replace(".vue", "");
            }
            return null;
        },
        pageLanguage: function() {
            return this.$store.state.defaultStore.pageLanguage;
        }
    },
    methods: {
        getPage() {
            const self = this;

            ApiService.get(
                "/page" + (self.$route.path !== "/" ? self.$route.path : "")
            )
                .then(response => {
                    console.log(response.data.data);
                    self.pageData = response.data.data;
                    document.title = self.pageData.name;
                })
                .catch(err => {
                    console.log(err);
                    self.$router.push({ name: "404" });
                });
        }
    }
};
</script>

<style scoped></style>
